import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  confirm,
  ResetPasswordRequest,
  login,
  LoginRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
  loginWithGoogle,
  impersonate,
} from 'api/auth.api';

import {
  deleteImpersonateModeData,
  deleteToken,
  deleteUser,
  impersonatePersistOriginalUser,
  persistToken,
  persistUser,
  readToken,
  readUser,
} from 'services/localStorage.service';
import { User } from 'types';
import * as Sentry from '@sentry/react';
import { ImpersonateModeData } from 'types/user';

export interface AuthSlice {
  token: string | null;
  user: User | null;
}

const initialState: AuthSlice = {
  token: readToken(),
  user: readUser(),
};

export interface LoginWithGoogleRequest {
  email: string;
}

export const doLogin = createAsyncThunk(
  'auth/doLogin',
  async (loginPayload: LoginRequest, { rejectWithValue }) => {
    try {
      const res = await login(loginPayload);
      persistUser(res.data.user);
      persistToken(res.data.token);
      Sentry.setUser({ email: res.data.user.email });
      Sentry.setUser({ id: res.data.user._id });
      return res;
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doLoginWitGoogle = createAsyncThunk(
  'auth/doLoginWthGoogle',
  async (loginPayload: LoginWithGoogleRequest, { rejectWithValue }) => {
    try {
      const res = await loginWithGoogle(loginPayload);
      persistUser(res.data.user);
      persistToken(res.data.token);
      Sentry.setUser({ email: res.data.user.email });
      Sentry.setUser({ id: res.data.user._id });
      return res;
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doImpersonate = createAsyncThunk(
  'auth/doImpersonate',
  async (employeeId: string, { rejectWithValue }) => {
    try {
      const res = await impersonate(employeeId);
      impersonatePersistOriginalUser();
      persistUser(res.data.user);
      persistToken(res.data.token);
      Sentry.setUser({ email: res.data.user.email });
      Sentry.setUser({ id: res.data.user._id });
      return res;
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doValidateConfirmToken = createAsyncThunk(
  'auth/doValidate',
  async (_, { rejectWithValue }) => {
    try {
      const currentUser = await confirm();
      return await persistUser(currentUser.data.user);
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest, { rejectWithValue }) => {
    try {
      return await resetPassword(resetPassPayload);
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload, { rejectWithValue }) => {
    try {
      return await verifySecurityCode(securityCodePayload);
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doSetNewPassword = createAsyncThunk(
  'auth/doSetNewPassword',
  async (newPasswordData: NewPasswordData, { rejectWithValue }) => {
    try {
      return await setNewPassword(newPasswordData);
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doLogout = createAsyncThunk('auth/doLogout', () => {
  deleteToken();
  deleteUser();
  deleteImpersonateModeData();
});

const returnToMyAccount = () => {
  return () => {
    const originalUser = localStorage.getItem('originalUser');
    const originalAccessToken = localStorage.getItem('originalAccessToken');
    let payload: ImpersonateModeData = {
      originalUser: null,
      originalAccessToken: null,
    };
    if (originalUser && originalAccessToken) {
      const user = JSON.parse(originalUser);
      persistUser(user);
      persistToken(originalAccessToken);
      Sentry.setUser({ email: user.email });
      Sentry.setUser({ id: user._id });
      deleteImpersonateModeData();
      payload = {
        originalUser: user,
        originalAccessToken,
      };
    }
    return {
      payload,
    };
  };
};

export const doReturnToMyAccount = createAction(
  'returnMyAccount',
  returnToMyAccount()
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload.data.token;
      state.user = action.payload.data.user;
    });
    builder.addCase(doLoginWitGoogle.fulfilled, (state, action) => {
      state.token = action.payload.data.token;
      state.user = action.payload.data.user;
    });
    builder.addCase(doImpersonate.fulfilled, (state, action) => {
      state.token = action.payload.data.token;
      state.user = action.payload.data.user;
    });
    builder.addCase(doReturnToMyAccount, (state, action) => {
      state.token = action.payload.originalAccessToken ?? null;
      state.user = action.payload.originalUser;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
