import {
  Col,
  Row,
  Table,
  Popover,
  Button,
  Space,
  Badge,
  Input,
  Checkbox,
  Spin,
} from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { useCallback, useEffect, useState } from 'react';
import User from '../../types/user';
import {
  EditFilled,
  LoadingOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  doDelete,
  doList,
  doUpdateOne,
  setLastSearch,
} from 'store/slices/userSlice';
import { getAllUsers } from 'api/user.api';
import {
  contractsPaths,
  globalPaths,
  kardexPaths,
  usersPaths,
} from 'constants/routePaths';
import type { CheckboxProps } from 'antd';
import * as Sentry from '@sentry/react';
import 'styles/sass/tables.scss';
import {
  faEye,
  faEdit,
  faFileSignature,
  faCalendarDay,
  faTrash,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'styles/sass/employees/employees-list.scss';
import { EmployeeListSettingBtnProps } from 'interfaces/employee.interfaces';
import { doImpersonate } from 'store/slices/authSlice';
import { LoginResponse } from 'api/auth.api';
import { redirectAfterLogin } from 'hooks/redirectAfterLogin';
import notificationHook from 'hooks/notificationHook';
import { AxiosResponse } from 'axios';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';

const UserListPage = () => {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useState('');
  const [usersData, setUsersData] = useState<User[]>();
  const [showInactiveUsers, setShowInactiveUsers] = useState(false);
  const lastSearch = useAppSelector((state) => state.user.lastUserSearch);
  const [loadingTable, setLoadingTable] = useState(false);
  const [impersonateLoading, setImpersonateLoading] = useState(false);

  useEffect(() => {
    dispatch(doList());
  }, [dispatch]);

  const searchUsers = useCallback(async () => {
    setLoadingTable(true);
    const users = await getAllUsers(searchParam);
    if (showInactiveUsers) {
      setUsersData(users.data);
    } else {
      const activeUsers = users.data.filter((user) => user.status === true);
      setUsersData(activeUsers);
    }
    setLoadingTable(false);
  }, [searchParam, showInactiveUsers]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchUsers();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, searchUsers]);

  useEffect(() => {
    const activeUsers = users.filter((user) => user.status === true);
    setUsersData(activeUsers);
  }, [users]);

  const onChangeSearchParam = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    dispatch(setLastSearch(input));
  };

  const handleShowInactiveUsers: CheckboxProps['onChange'] = (e) => {
    setShowInactiveUsers(e.target.checked);
  };

  useEffect(() => {
    if (lastSearch || lastSearch === '') {
      setSearchParam(lastSearch);
    }
  }, [lastSearch]);

  const onDeleteEmployee = async (data: User) => {
    const a = users.filter((user) => user._id === data._id);
    dispatch(doDelete(a[0]._id))
      .unwrap()
      .then(() => {
        notificationHook({
          type: 'success',
          message: `Usuario eliminado con éxito`,
        });
        setUsersData((pre) => {
          return pre ? pre.filter((user) => user._id !== data._id) : [];
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          message: statusMessages.user[
            currentStatus ? currentStatus : 500
          ] as string,
          type: 'error',
        });
      });
    return true;
  };

  const changeStatus = async (data: User) => {
    try {
      const a = users.filter((user) => user._id === data._id);
      const dataPayload = { _id: a[0]._id, status: !a[0].status };
      dispatch(doUpdateOne(dataPayload))
        .unwrap()
        .then(() => {
          setUsersData((prevUsers) => {
            return prevUsers
              ? prevUsers.map((user) => {
                  if (user._id === data._id) {
                    return { ...user, status: !user.status };
                  }
                  return user;
                })
              : [];
          });
        })
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            message: statusMessages.user[
              currentStatus ? currentStatus : 500
            ] as string,
            type: 'error',
          });
        });
    } catch (error) {
      Sentry.captureException(error);
      const currentStatus = (error as ApiError).statusCode;
      notificationHook({
        message: statusMessages.user[
          currentStatus ? currentStatus : 500
        ] as string,
        type: 'error',
      });
    }

    return true;
  };

  const onImpersonateEmployee = (employee: User) => {
    setImpersonateLoading(true);
    dispatch(doImpersonate(employee._id))
      .unwrap()
      .then((res: AxiosResponse<LoginResponse>) => {
        if (res.data.user) {
          const navigatePath = redirectAfterLogin(res.data.user.role);
          navigate(navigatePath);
        }
      })
      .catch((error) => {
        Sentry.setContext('Props', employee);
        Sentry.captureException(error as Error);
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          message: statusMessages.auth[
            currentStatus ? currentStatus : 500
          ] as string,
          type: 'error',
          duration: 5,
        });
      });
    setImpersonateLoading(false);
  };

  const settingButton = ({
    dataCy,
    onClick,
    icon,
    text,
    additionalClassName,
    loading,
    disabled,
  }: EmployeeListSettingBtnProps) => (
    <Spin spinning={loading ?? false} indicator={<LoadingOutlined />}>
      <Button
        data-cy={dataCy}
        type="text"
        className={`setting-btn ${additionalClassName ?? ''}`}
        onClick={() => {
          onClick();
        }}
        disabled={disabled ?? false}
      >
        <span>{icon}</span>
        <p>{text}</p>
      </Button>
    </Spin>
  );

  const employeeSettings = (employee: User) => (
    <Row justify="center" className="settings">
      <Col>
        {settingButton({
          dataCy: 'employee-detail',
          onClick: () => navigate(`${usersPaths.userDetail}/${employee._id}`),
          icon: <FontAwesomeIcon icon={faEye} />,
          text: 'Detalle',
        })}
        {settingButton({
          dataCy: 'edit-employee-btn',
          onClick: () => navigate(`${usersPaths.editUser}/${employee._id}`),
          icon: <FontAwesomeIcon icon={faEdit} />,
          text: 'Editar',
        })}
        {settingButton({
          dataCy: 'employee-contracts',
          onClick: () =>
            navigate(`${usersPaths.userContracts}/${employee._id}`),
          icon: <FontAwesomeIcon icon={faFileSignature} />,
          text: 'Contratos',
        })}
        {settingButton({
          dataCy: 'employee-kardex',
          onClick: () => navigate(`${kardexPaths.base}/${employee._id}`),
          icon: <FontAwesomeIcon icon={faCalendarDay} />,
          text: 'Kardex',
        })}
        {settingButton({
          dataCy: 'impersonate-employee-btn',
          onClick: () => onImpersonateEmployee(employee),
          icon: <FontAwesomeIcon icon={faUserGroup} />,
          text: 'Impersonar',
          loading: impersonateLoading,
          disabled: !employee.status,
        })}
        {settingButton({
          dataCy: 'delete-employee-btn',
          onClick: () => onDeleteEmployee(employee),
          icon: <FontAwesomeIcon icon={faTrash} />,
          text: 'Eliminar',
          additionalClassName: 'delete-button',
        })}
      </Col>
    </Row>
  );

  const columns: ColumnsType<User> = [
    {
      title: 'ID',
      dataIndex: 'idEmployee',
      align: 'center',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => Number(a.idEmployee) - Number(b.idEmployee),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      width: 150,
    },
    {
      title: 'Nombre(s)',
      dataIndex: 'name',
      align: 'center',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Apellido(s)',
      dataIndex: 'lastName',
      align: 'center',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'email',
      align: 'center',
      responsive: ['lg', 'xl', 'xxl'],
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Estatus',
      align: 'center',
      responsive: ['lg', 'xl', 'xxl'],
      width: 200,
      render: (record: User) => (
        <div>
          {record.status == true ? (
            <Space data-cy={`status-active-${record._id}`}>
              <Badge status="success" />
              Activo
            </Space>
          ) : (
            <Space data-cy={`status-inactive-${record._id}`}>
              <Badge status="error" />
              Inactivo
            </Space>
          )}
          <Popover
            content={
              record.status ? (
                <Button
                  data-cy={`button-inactive-${record._id}`}
                  type="primary"
                  danger
                  block
                  onClick={() => {
                    changeStatus(record);
                  }}
                >
                  Desactivar
                </Button>
              ) : (
                <Button
                  data-cy={`button-active-${record._id}`}
                  type="primary"
                  block
                  onClick={() => {
                    changeStatus(record);
                  }}
                >
                  Activar
                </Button>
              )
            }
            trigger="click"
            placement="left"
          >
            <EditFilled
              data-cy={`edit-status-${record._id}`}
              style={{ marginLeft: 5 }}
            />
          </Popover>
        </div>
      ),
    },
    {
      title: 'Opciones',
      align: 'center',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      width: 100,
      render: (text: string, record: User, index: number) => (
        <div
          className="settings"
          key={`user-${index}-settings`}
          data-cy={`user-${record.idEmployee}-settings`}
        >
          <Popover
            placement="top"
            content={employeeSettings(record)}
            trigger="click"
            className="settings-conatiner"
          >
            <Button type="text">
              <SettingOutlined />
            </Button>
          </Popover>
        </div>
      ),
    },
  ];

  const tableProps: TableProps<User> = {
    bordered: true,
    tableLayout: 'fixed',
    size: 'small',
    className: 'table-ps',
    pagination: { position: ['bottomCenter'], pageSize: 50 },
  };

  return (
    <div id="employees-list">
      <PageTitle>Lista de empleados</PageTitle>
      <h2 className="title">Lista de empleados</h2>
      <Row justify="space-between" gutter={16} className="my-5">
        <Col xs={12} className="search-wrapper">
          <p>Búsqueda: </p>
          <Input
            data-cy="search-employees"
            type="text"
            value={lastSearch}
            onChange={onChangeSearchParam}
            className="input-search"
          />
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => navigate(`/${globalPaths.uploadPhotos}`)}
            id="uploadEmployeesBtn"
            className="mr-2"
          >
            Subir fotos de perfil
          </Button>
          <Button
            type="primary"
            onClick={() => navigate(usersPaths.uploadUsers)}
            id="uploadEmployeesBtn"
            className="mr-2"
          >
            Subir empleados
          </Button>
          <Button
            type="primary"
            onClick={() =>
              navigate(
                `${contractsPaths.basePath}/${contractsPaths.uploadContracts}`
              )
            }
            className="mr-2"
          >
            Subir contratos
          </Button>
          <Button
            type="primary"
            onClick={() => navigate(usersPaths.newUser)}
            id="newEmployeeBtn"
            data-cy="newEmployeeBtn"
          >
            Agregar empleado
          </Button>
        </Col>
      </Row>
      <Row justify="end" gutter={16}>
        <Col>
          <Checkbox data-cy="showInactive" onChange={handleShowInactiveUsers}>
            Mostrar Inactivos
          </Checkbox>
        </Col>
      </Row>
      <Table
        data-cy="employeesTable"
        {...tableProps}
        loading={loadingTable}
        dataSource={usersData}
        columns={columns}
        id="employeesTable"
        rowKey={'idEmployee'}
      />
    </div>
  );
};

export default UserListPage;
