import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/reduxHooks';
import { WithChildrenProps } from 'types';
import { authPaths } from 'constants/routePaths';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token);
  return token ? (
    <>{children}</>
  ) : (
    <Navigate to={`${authPaths.basePath}/${authPaths.login}`} replace />
  );
};

export default RequireAuth;
