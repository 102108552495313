import {
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Table,
  TableProps,
} from 'antd';
import { User } from 'types';
import { useCallback, useEffect, useState } from 'react';
import { Device, UserInDeviceResponse } from 'types/devices';
import { doList } from 'store/slices/devicesSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { bulkCommand, getUserInDevice } from 'api/devices.api';
import { CommandsType } from 'types/commands';
import { SearchOutlined } from '@ant-design/icons';
import { format } from 'date-fns/format';
import { dateFormat } from 'constants/Dates';
import { SliceStatus } from 'constants/enums/slices';
import { BackButton } from 'components/common/BackButton/BackButton';
import RenderPhotos from './profile/RenderPhotos';

interface UserDetailProps {
  user: User;
}

export const UserDetail = ({ user }: UserDetailProps) => {
  const dispatch = useAppDispatch();
  const { devices, status: deviceSliceStatus } = useAppSelector(
    (state) => state.device
  );
  const [devicesData, setDevicesData] = useState<Device[]>([]);
  const [deviceUserData, setDeviceUserData] = useState<UserInDeviceResponse[]>(
    []
  );
  const [synced, setSynced] = useState(false);

  useEffect(() => {
    if (deviceSliceStatus === SliceStatus.Empty) {
      dispatch(doList());
    } else if (deviceSliceStatus === SliceStatus.Fulfilled) {
      setDevicesData(devices);
    }
  }, [dispatch, devicesData, deviceSliceStatus, devices]);

  const updateData = useCallback(() => {
    const prom: Promise<UserInDeviceResponse>[] = [];
    devicesData.forEach((dev) => {
      prom.push(updateDataStatus(dev.serialNumber, user.idEmployee));
    });
    Promise.all(prom).then((data) => setDeviceUserData(data));
  }, [devicesData, user.idEmployee]);

  useEffect(() => {
    if (devicesData.length > 0 && !synced) {
      setSynced(true);
      updateData();
    }
  }, [devicesData, synced, updateData]);

  const updateDataStatus = async (sn: string, pin: string) => {
    return (await getUserInDevice({ serialNumber: sn, pin })).data;
  };

  const pushInfoCommand = async (sn: string, pin: string) => {
    const payloadArray: CommandsType = {
      serialNumber: sn,
      command: 'insertUser',
      employeeId: Number(pin),
    };
    await bulkCommand([payloadArray]);
    await updateData();
  };
  const pushFingerUpdateCommand = async (
    sn: string,
    pin: string,
    fid: number
  ) => {
    const payloadArray: CommandsType = {
      serialNumber: sn,
      command: 'updateFingerPrint',
      employeeId: Number(pin),
      fid: fid,
    };
    await bulkCommand([payloadArray]);
    await updateData();
  };
  const pullFingrerPrint = async (sn: string, pin: number) => {
    const payloadArray: CommandsType = {
      serialNumber: sn,
      command: 'getFinger',
      employeeId: pin,
    };
    await bulkCommand([payloadArray]);
    await updateData();
  };

  const columns: TableProps<UserInDeviceResponse>['columns'] = [
    {
      title: 'Dispositivo',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
    },
    {
      title: 'Biometricos?',
      dataIndex: 'numBio',
      key: 'numBio',
    },
    {
      title: 'info Server',
      dataIndex: 'commandSentDate',
      key: 'commandSentDate',
      render: (_a, record) => {
        if (record.commandSentDate) {
          return `${format(new Date(record.commandSentDate), dateFormat)}`;
        } else {
          return (
            <>
              <Button
                onClick={() => pushInfoCommand(record.serialNumber, record.pin)}
              >
                Subir
              </Button>
            </>
          );
        }
      },
    },
    {
      title: 'info Server id',
      dataIndex: 'commandSentId',
      key: 'commandSentId',
      render: (_a, record) => {
        if (record.commandSentDate) {
          return `${record.commandSentId}`;
        } else {
          return <>NA</>;
        }
      },
    },
    {
      title: 'info Dispositivo',
      key: 'commandProcessedDate',
      dataIndex: 'commandProcessedDate',
      render: (_a, record) => {
        if (record.commandProcessedDate) {
          return `${format(new Date(record.commandProcessedDate), dateFormat)}`;
        } else {
          return <>NA</>;
        }
      },
    },
    {
      title: 'Options',
      render: (_a, record) => {
        if (record.numBio === 0) {
          return (
            <>
              NA{' '}
              <Button
                type="link"
                onClick={() =>
                  pullFingrerPrint(record.serialNumber, Number(record.pin))
                }
                className="icon-primary"
              >
                <SearchOutlined />
              </Button>
            </>
          );
        } else {
          return record.fingerData.map((f) => (
            <>
              FID: {`${f.fid}`} <br />
              {f.commandSentId && f.commandSentDate && (
                <>
                  <i>{`CMD: ${f.commandSentId} at ${format(
                    new Date(f.commandSentDate),
                    dateFormat
                  )}`}</i>
                </>
              )}
              {!f.commandSentId && record.commandProcessedDate && (
                <Button
                  onClick={() =>
                    pushFingerUpdateCommand(
                      record.serialNumber,
                      record.pin,
                      f.fid
                    )
                  }
                >
                  Subir
                </Button>
              )}
              <br />
              {f.commandProcessedDate && (
                <>
                  <b>{`validado ${format(
                    new Date(f.commandProcessedDate),
                    dateFormat
                  )}`}</b>
                </>
              )}
              <br />
              ------
              <br />
            </>
          ));
        }
      },
    },
  ];

  return (
    <div id="employee-detail-container">
      <h2 className="title">Detalle de empleado</h2>
      <BackButton />
      <div id="employee-detail-table">
        {user && (
          <Row gutter={24}>
            <Col xs={12}>
              <Descriptions title="" layout="horizontal" bordered>
                <Descriptions.Item className="id" label="ID" span={3}>
                  {user.idEmployee}
                </Descriptions.Item>
                <Descriptions.Item className="name" label="Nombre(s)" span={3}>
                  {user.name}
                </Descriptions.Item>
                <Descriptions.Item
                  className="lastName"
                  label="Apellido(s)"
                  span={3}
                >
                  {user.lastName}
                </Descriptions.Item>
                <Descriptions.Item
                  className="email"
                  label="Correo electrónico"
                  span={3}
                >
                  {user.email}
                </Descriptions.Item>
                <Descriptions.Item className="phone" label="Teléfono" span={3}>
                  {user.phone}
                </Descriptions.Item>
                <Descriptions.Item className="status" label="Estatus" span={3}>
                  {user.status == true ? (
                    <Badge status="success" text="Activo" />
                  ) : (
                    <Badge status="error" text="Inactivo" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item className="role" label="Rol" span={3}>
                  {user.role}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col xs={12}>
              <Card
                size="small"
                title="Foto de perfil"
                className="profile-card"
              >
                <RenderPhotos
                  employeeId={user.idEmployee}
                  lastName={user.lastName}
                  name={user.name}
                  size="LG"
                />
              </Card>
            </Col>
          </Row>
        )}
      </div>
      <Table
        columns={columns}
        dataSource={deviceUserData}
        bordered
        tableLayout="fixed"
        size="small"
        pagination={{ position: ['bottomCenter'], pageSize: 50 }}
        className="table-ps"
      />
    </div>
  );
};
