import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Alert, Button, Col, Row, UploadProps } from 'antd';
import { UploadFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import { employeeExists, sendImageToQueue } from 'api/user.api';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import notificationHook from 'hooks/notificationHook';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';
import { presignPutImage } from 'api/file.api';

interface CustomUploadFile extends UploadFile {
  employeeExists?: boolean;
}

const UploadPhotos = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<CustomUploadFile[]>([]);

  const props: UploadProps = {
    name: 'file',
    accept: 'image/jpeg',
    multiple: true,
    fileList,
    beforeUpload: async (file) => {
      const isJPEG = file.type === 'image/jpeg';
      if (!isJPEG) {
        notificationHook({
          type: 'error',
          message: `El archivo ${file.name} no es de tipo .jpeg o .jpg`,
        });
        return;
      }
      setFileList((prevFileList) => [
        ...prevFileList,
        {
          name: file.name,
          status: 'uploading',
        } as CustomUploadFile,
      ]);
      try {
        const nameOnly = file.name.split('.').slice(0, -1).join('.');
        const isEmployee = await employeeExists(nameOnly);
        if (isEmployee.data.exists) {
          const presigned = await presignPutImage({
            fileName: nameOnly,
            folder: 'profile_picture/original',
          });
          if (presigned) {
            await fetch(presigned.data.url, {
              method: 'PUT',
              body: file,
            }).catch((error) => {
              const currentStatus = (error as ApiError).statusCode;
              notificationHook({
                type: 'error',
                message: statusMessages.files[
                  currentStatus ? currentStatus : 500
                ] as string,
              });
            });
            await sendImageToQueue(presigned.data.fileName);
          }
        }
        setFileList((prevFileList) =>
          prevFileList.map((item) =>
            item.name === file.name
              ? {
                  ...item,
                  status: 'done',
                  employeeExists: isEmployee.data.exists,
                }
              : item
          )
        );
      } catch (e) {
        setFileList((prevFileList) =>
          prevFileList.map((item) =>
            item.name === file.name
              ? {
                  ...item,
                  status: 'done',
                  employeeExists: false,
                }
              : item
          )
        );
      }
    },
    onDrop() {
      notificationHook({
        type: 'warning',
        message: 'Removiendo imagen, por favor espera.',
      });
    },

    itemRender: (_, file: CustomUploadFile) => (
      <>
        {file.status === 'uploading' && (
          <>
            <LoadingOutlined spin style={{ marginRight: '8px' }} /> {file.name}
          </>
        )}
        {file.status === 'done' &&
          (!file.employeeExists ? (
            <>
              <CloseCircleFilled className="badPhotoIcon" /> {file.name}{' '}
              <span className="badPhotoText">el id no existe</span>
            </>
          ) : (
            <>
              <CheckCircleFilled className="successPhotoIcon" /> {file.name}{' '}
              <span className="successPhotoText">
                La imagen fue subida con éxito pero aun puede tardar unos
                minutos en reflejarse el cambio.
              </span>
            </>
          ))}
      </>
    ),
  };

  return (
    <>
      <PageTitle>Subir fotos</PageTitle>
      <h2 className="title">Subir fotos de perfil</h2>
      <Button className="back-page" onClick={() => navigate(-1)}>
        <ArrowLeftOutlined /> Regresar
      </Button>
      <Row justify="center">
        <Col className="mb-5" xs={24}>
          <Alert
            description={
              <ul>
                <li>Puedes subir varias imágenes.</li>
                <li>El nombre del archivo debe ser el ID de empleado.</li>
                <li>Las imágenes deben estar en formato JPG.</li>
              </ul>
            }
            type="warning"
            showIcon={false}
          />
        </Col>
      </Row>
      <div className="upload-dragger">
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Haz clic o arrastra los archivos en esta área para subirlos
          </p>
          <p className="ant-upload-hint">
            Recuerda subir archivos únicamente <b>.jpg</b>
          </p>
        </Dragger>
      </div>
    </>
  );
};

export default UploadPhotos;
