import { UserRoles } from 'constants/enums/roles';
import { Schedule } from './schedule';

export type ScheduleInterval = {
  dayOfWeek: number;
  from: string;
  to: string;
};
type User = {
  _id: string;
  idEmployee: string;
  email: string;
  name: string;
  lastName: string;
  status: boolean;
  supervisor?: User;
  role: UserRoles;
  currentSchedule: ScheduleInterval[];
  phone: string;
  token: string;
  created_at?: Date;
  profile_picture?: string;
};

export type UserUpdatePayloadType = {
  _id: string;
  idEmployee?: string;
  password?: string;
  name?: string;
  lastName?: string;
  email?: string;
  status?: boolean;
  supervisor?: User | string;
  currentSchedule?: ScheduleInterval[];
};

export default User;

export const initValues = {
  _id: '',
  idEmployee: '',
  email: '',
  name: '',
  lastName: '',
  status: true,
  currentSchedule: [],
  phone: '',
  token: '',
};

export type EmployeeSimpleData = {
  _id: string;
  idEmployee: string;
  email: string;
  name: string;
  lastName: string;
};

export type EmployeeWithScheduleOfToday = {
  _id: string;
  contractType: string;
  contractCode: string;
  workDayKind: 'fija' | 'por horas';
  status: boolean;
  employee: EmployeeSimpleData;
  schedules: Schedule[];
};

export type ImpersonateModeData = {
  originalAccessToken: string | null;
  originalUser: User | null;
};
