import { Col, Row, Tag } from 'antd';
import RenderPhotos from 'components/employees/profile/RenderPhotos';
import { EmployeeRowProps } from 'interfaces/employee.interfaces';
import { removeZOnHourtoDisplay } from 'libs/dateFormaterPetition';

export const EmployeeRow = ({ todaysEmployeeSchedules }: EmployeeRowProps) => {
  return (
    <div id={`employee-row-${todaysEmployeeSchedules._id}`}>
      <Row align="middle">
        <Col xl={2}>
          <RenderPhotos
            employeeId={todaysEmployeeSchedules.employee.idEmployee}
            lastName={todaysEmployeeSchedules.employee.lastName}
            name={todaysEmployeeSchedules.employee.name}
            size="SM"
          />
        </Col>
        <Col xl={22}>
          <p className="employee-name">
            <b>{`${todaysEmployeeSchedules.employee.name} ${todaysEmployeeSchedules.employee.lastName ?? ''}`}</b>
          </p>
          <Row>
            {todaysEmployeeSchedules.schedules.map((schedule) => (
              <Col
                xl={6}
                className="employee-schedule"
                key={`employee-schedule-${schedule._id}`}
              >
                <Tag>
                  {removeZOnHourtoDisplay(schedule.from)} -{' '}
                  {removeZOnHourtoDisplay(schedule.to)}
                </Tag>
              </Col>
            ))}
          </Row>
          <span className="contract-type">
            {todaysEmployeeSchedules.contractType}
          </span>
        </Col>
      </Row>
    </div>
  );
};
