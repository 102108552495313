export default {
  201: 'Carrera creada con éxito',
  202: 'Se editó la carrera con éxito',
  400: 'No se puede eliminar una especialidad asignada a un grupo',
  418: 'No se puede eliminar una especialidad asignada a una materia',
  419: 'No se pudo eliminar la carrera porque tiene una materia relacionada',
  427: 'La clave ya se encuentra registrada',
  431: 'No se pudo eliminar la carrera porque tiene un grupo relacionado',
  448: 'Uno o más coordinadores no existen',
  404: 'No se encontró la carrera',
};
